// Manage The Token  being stored or being received

const TOKEN_KEY = "access_token";


exports.getToken = () => {
    return localStorage.getItem(TOKEN_KEY)
}

exports.saveToken = (token) => {
    localStorage.setItem(TOKEN_KEY, token)
} 

exports.removeToken = (token) => {
    localStorage.removeItem(TOKEN_KEY)
}
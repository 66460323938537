import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue';
import Sender from '../views/Sender.vue'
import SendMsg from '../views/SendMsg.vue'
import Broadcast from '../views/Broadcast.vue'
import LogBroadcast from '../views/LogBroadcast.vue'
import LogMsg from '../views/LogMsg.vue'
import User from '../views/User.vue'
import Chat from '../views/Chat.vue'
import Contact from '../views/Contact.vue'
import Group from '../views/Group.vue'
import Login from '@/views/Login.vue'
import Registrasi from '@/views/Registrasi.vue'
import Documentation from '@/views/Documentation.vue'
import Template from '@/views/Template.vue'
import Profile from '@/views/Profile.vue'

// Service token
import {
	getToken
} from '@/service/token.js';

Vue.use(VueRouter)


// TABS 
let tabs_message = [
	{
		name: 'Send Message',
		to: '/send-message'
	},
	{
		name: 'History Message',
		to: '/log-message'
	}
]

let tabs_broadcast = [
	{
		name: 'Broadcast',
		to: '/broadcast'
	},
	{
		name: 'History Broadcast',
		to: '/log-broadcast'
	},
	{
		name: 'Template',
		to: '/template'
	}
]

let tabs_contact = [
	{
		name: 'Contact',
		to: '/contact'
	},
	{
		name: 'Group',
		to: '/group'
	},
];


const routes = [
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {
			title: 'Login',
			isOnMenu: false
		}
	},
	{
		path: '/registrasi',
		name: 'registrasi',
		component: Registrasi,
		meta: {
			title: 'Registrasi',
			icon: '',
			isOnMenu: false,
			requiresAuth: false,
		}
	},
	{
		path: '/profile',
		name: 'Profile',
		component: Profile,
		meta: {
			title: 'Profile',
			icon: 'mdi-view-dashboard',
			requiresAuth: true,
			isOnMenu: false,
		}
	},
	{
		path: '/',
		name: 'Dashboard',
		component: Dashboard,
		meta: {
			title: 'Dashboard',
			icon: 'mdi-view-dashboard',
			requiresAuth: true,
			isOnMenu: true,
		}
	},
	{
		path: '/sender',
		name: 'Sender',
		component: Sender,
		meta: {
			title: 'Sender',
			icon: 'mdi-chat-processing-outline',
			requiresAuth: true,
			isOnMenu: true,
		}
	},
	{
		path: '/contact',
		name: 'Contact',
		component: Contact,
		meta: {
			title: 'Contact',
			icon: 'mdi-contacts-outline',
			requiresAuth: true,
			isOnMenu: true,
		}
	},
	// {
	// 	path: '/group',
	// 	name: 'Group',
	// 	component: Group,
	// 	meta: {
	// 		title: 'Group',
	// 		icon: 'mdi-account-details',
	// 		requiresAuth: true,
	// 		isOnMenu: false,
	// 		tabs: tabs_contact
	// 	}
	// },
	{
		path: '/send-message',
		name: 'Send Message',
		component: SendMsg,
		meta: {
			title: 'Message',
			icon: 'mdi-send-outline',
			requiresAuth: true,
			isOnMenu: true,
			tabs: tabs_message
		}
	},
	{
		path: '/log-message',
		name: 'History Message',
		component: LogMsg,
		meta: {
			title: 'Log Message',
			icon: 'mdi-reload',
			requiresAuth: true,
			isOnMenu: false,
			tabs: tabs_message
		}
	},
	// {
	// 	path: '/broadcast',
	// 	name: 'Broadcast',
	// 	component: Broadcast,
	// 	meta: {
	// 		title: 'Broadcast',
	// 		icon: 'mdi-send-check-outline',
	// 		requiresAuth: true,
	// 		isOnMenu: true,
	// 		tabs: tabs_broadcast
	// 	}
	// },
	// {
	// 	path: '/log-broadcast',
	// 	name: 'History Broadcast',
	// 	component: LogBroadcast,
	// 	meta: {
	// 		title: 'Log Broadcast',
	// 		icon: 'mdi-reload',
	// 		requiresAuth: true,
	// 		isOnMenu: false,
	// 		tabs: tabs_broadcast
	// 	}
	// },
	{
		path: '/template',
		name: 'Template',
		component: Template,
		meta: {
			title: 'Template',
			icon: 'mdi-reload',
			requiresAuth: true,
			isOnMenu: false,
			tabs: tabs_broadcast
		}
	},
	{
		path: '/user',
		name: 'User',
		component: User,
		meta: {
			title: 'User',
			icon: 'mdi-account-group-outline',
			requiresAuth: true,
			isOnMenu: false
		}
	},
	// {
	// 	path: '/chat',
	// 	name: 'oneChat',
	// 	component: Chat,
	// 	meta: {
	// 		title: 'oneChat',
	// 		icon: 'mdi-forum',
	// 		requiresAuth: true,
	// 		isOnMenu: true
	// 	}
	// },
	// {
	// 	path: '/api',
	// 	name: 'Api',
	// 	component: Documentation,
	// 	meta: {
	// 		title: 'Api',
	// 		icon: 'mdi-application-brackets-outline',
	// 		requiresAuth: true,
	// 		isOnMenu: true
	// 	}
	// },


]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	// IF META OBJECT IS EXIST
	document.title = process.env.VUE_APP_BRAND + " | " + to.meta.title;
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

	if (requiresAuth) {
		if (getToken() == null) {
			next({
				path: '/login',
				params: {
					nextUrl: to.fullPath
				}
			})
		} else {
			next();
		}
	} else {
		next();
	}

})

export {
	router,
	routes
}
// export default router
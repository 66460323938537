export const SET_USER = (state, user) => {
    state.user.user = user
}

export const SET_LIST_USER = (state, user) => {
    state.user.list = user
}

export const SET_DETAIL_USER = (state, user) => {
    state.user.form = user
}

// contact

export const SET_LIST_CONTACT = (state, contact) => {
    state.contact.list = contact
}

export const SET_CONTACT = (state, contact) => {
    state.contact.form = contact
}

export const SET_OPTION_CONTACT = (state, option) => {
    state.contact.option = option
}

// message

export const SET_LIST_MESSAGE = (state, message) => {
    state.message.list = message
}

// worker
export const SET_LIST_WORKER = (state, worker) => {
    state.worker.list = worker
}

export const SET_OPTION_WORKER = (state, worker) => {
    // state.worker.option = worker
    state.worker.option = worker
}

// socket
export const SOCKET_CONNECT = (state) => {
    state.isConnected = true;
}

export const SOCKET_DISCONNECT = (state) => {
    state.isConnected = false;
}

export const SOCKET_MESSAGECHANNEL = (state, message) => {
    state.socketMessage = message
}

export const SOCKET_LIST_WORKER = (state, worker) => {
    state.listWorker = worker
}

// loading 

export const SET_LOADING = (state, load) => {
    state.loading.isLoading = load
}

// group

export const SET_LIST_GROUP = (state, group) => {
    state.group.list = group
}

export const SET_GROUP = (state, group) => {
    state.group.form = group
}

// validation error

export const SET_VALIDATION_ERROR = (state, validation) => {
    state.validation = validation
}
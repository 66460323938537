<template>
    <v-container fluid>
        <v-row>
            <v-col >
                <v-tabs v-model="tab" color="#00E676">
                    <v-tab href="#tab-1" @click="resetTab()">
                        <v-icon>mdi-account-multiple</v-icon>
                    </v-tab>
                    <v-tab href="#tab-2" @click="resetTab()">
                        <v-icon>mdi-account-group</v-icon>
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item :value="'tab-1'">
                        <v-row class="pa-0 ma-0">
                            <v-col v-for="(contact, id) in contacts" :key="id" cols="12" class="pt-0">
                                <v-checkbox color="#00E676" v-model="selected" :label="contact.contact_name"
                                    :value="contact.contact_phonenumber" hide-details></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-tab-item>

                    <v-tab-item :value="'tab-2'">
                        <v-row class="pa-0 ma-0">
                            <v-col>
                                <v-radio-group v-model="column" column v-for="(group, id) in groups" :key="id" cols="12"
                                    class="pt-0">
                                    <v-radio :label="group.group_name" :value="group.group_id" color="#00E676">
                                    </v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>

            <v-col class="d-flex justify-center">
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete 
                            class="rounded-lg"
                            :items="worker" 
                            :error-messages="validation.session_name"
                            item-text="worker_session_name" 
                            label="Kode Pengirim" 
                            dense 
                            outlined
                            color="rgb(63 230 117)" 
                            hide-details="auto" 
                            v-model="form.session_name">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea 
                            class="rounded-lg"
                            label="Isi Pesan" 
                            dense 
                            outlined 
                            color="rgb(63 230 117)" 
                            rows="3" 
                            :error-messages="validation.message_content"
                            row-height="25" 
                            hide-details="auto" 
                            v-model="form.message_content"></v-textarea>
                    </v-col>
                    <v-col>
                        <v-row>
                            <v-col cols="12" xs="6" sm="6" md="3">
                                <v-btn elevation="2" class="rounded-lg pa-5" block color="warning">Template</v-btn>
                            </v-col>
                            <v-col cols="12" xs="6" sm="6" md="9">
                                <v-btn elevation="2" dark class="rounded-lg pa-5" color="rgb(63 230 117)" block
                                    @click="sendMsg()">Kirim
                                </v-btn>
                            </v-col>
                            <v-col>
                                <p style="color: grey">Jika akan mengirim gambar atau file, silahkan isi URL file /
                                    Image Link gambar atau file harus disertakan dengan ekstensinya.<br>Contoh <i
                                        style="color: orange">https://picsum.photos/id/995/200/300.jpg</i></p>
                                <p style="color: rgb(63 230 117)"><b>Jenis Ekstensi file yang dapat dikirim dalam
                                        format:</b><br><i style="color: orange">.doc .pdf .xis .xlsx .csv .gif .jpg .png
                                        .mp4 .mp3</i></p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                tab: null,
                column: '',
                selected: [],
            }
        },

        computed: {
            contacts() {
                return this.$store.state.contact.list
            },

            groups() {
                return this.$store.state.group.list
            },

            worker() {
                return this.$store.state.worker.list
            },

            form() {
                return this.$store.state.outbox.broadcastGroup
            },

            user() {
                return this.$store.state.user.user
            },

            validation() {
                return this.$store.state.validation.data
            }
        },

        sockets: {
            connect: function () {
                this.$socket.emit('LIST_WORKER', this.user)
            },
            COUNTER_LIST_WORKER: function (data) {
                this.$store.dispatch('getListWorker', data)
            }
        },

        mounted() {
            this.$socket.emit('LIST_WORKER', this.user)
            this.$store.dispatch('getListContact')
            this.$store.dispatch('getListGroup')
        },

        methods: {
            resetValidation() {
                this.$store.state.validation = {
                    success: true,
                    message: '',
                    error_code: '',
                    data: {}
                }
            },

            resetTab() {
                this.column = ''
                this.selected = []
            },

            async sendMsg() {
                if (this.selected.length == 0 && this.column == '') {
                    await this.$swal.fire({
                        icon: 'error',
                        title: 'Belum ada tujuan yang dipilih!!',
                        showConfirmButton: true,
                    })
                }

                if (this.selected.length != 0) {
                    this.form.group_id = this.selected

                    let success = await this.$store.dispatch('broadcastContact', this.$store.state.outbox
                        .broadcastGroup)
                    if (success) {
                        this.resetValidation();
                        await this.$swal.fire({
                            icon: 'success',
                            title: 'Broadcast pesan terkirim!!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.selected = []
                        return this.$store.state.outbox.broadcastGroup = {
                            message_content: '',
                            group_id: '',
                            session_name: ''
                        }
                    }

                }

                if (this.column != '') {
                    this.form.group_id = this.column
                    let success = await this.$store.dispatch('broadcastGroup', this.$store.state.outbox
                        .broadcastGroup)

                    if (success) {
                        this.resetValidation();
                        await this.$swal.fire({
                            icon: 'success',
                            title: 'Broadcast pesan terkirim!!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.column = ''
                        return this.$store.state.outbox.broadcastGroup = {
                            message_content: '',
                            group_id: '',
                            session_name: ''
                        }
                    }
                    if (!success && this.validation.group != undefined) {
                        await this.$swal.fire({
                            icon: 'error',
                            title: this.validation.group,
                            showConfirmButton: true,
                        })
                    }
                }
            }
        }
    }
</script>
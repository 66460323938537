<template>
  <v-col style="height: 100%; display: none;">
    <h3 class="grey--text pt-2 pb-1">Date Range</h3>
    <v-row class="py-3">
      <v-col cols="12" sm="6">
        <v-card outlined class="pa-2" elevation="0" style="border-style: solid">
          <v-col class="pa-3 px-5 d-flex flex-wrap align-center">
            <v-col cols="12" sm="5" md="5" lg="5" class="py-0">
              <v-col cols="12" class="pa-0" style="border-right-style: solid; border-width: 1px; border-color: grey;">
                <div>Date Start</div>
                <v-col class="d-flex flex-wrap align-center px-0">
                  <v-col cols="6" sm="6" class="pa-0">
                    <h4 class="grey--text lighten-2 mr-2" v-if="dateStart == ''">Select Date</h4>
                    <h4 class="grey--text lighten-2 mr-2" v-else>{{ dateStart }}</h4>
                  </v-col>
                  <v-col cols="6" sm="6" class="py-0 px-3 d-flex justify-end">
                    <v-menu v-model="startDate" :close-on-content-click="true" transition="scale-transition" offset-y
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small v-on="on" v-bind="attrs" elevation="0" color="rgb(63 230 117)" height="30px"
                          class="">
                          <v-icon small color="white">mdi-calendar</v-icon>
                        </v-btn>
                      </template>
                      <v-date-picker :min="dateStart" :max="nowDate" locale="id" type="date" color="rgb(63 230 117)"
                        @change="dateEnd = ''" v-model="dateStart"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-col>
              </v-col>
            </v-col>
            <v-col cols="12" sm="5" class="py-0">
              <v-col cols="12" class="pa-0" style="border-right-style: solid; border-width: 1px; border-color: grey;">
                <div>Date End</div>
                <v-col class="d-flex flex-wrap align-center px-0">
                  <v-col cols="6" sm="6" class="pa-0">
                    <h4 class="grey--text lighten-2 mr-2" v-if="dateEnd == ''">Select Date</h4>
                    <h4 class="grey--text lighten-2 mr-2" v-else>{{ dateEnd }}</h4>
                  </v-col>
                  <v-col cols="6" sm="6" class="pa-0 px-3 d-flex justify-end">
                    <v-menu v-model="endDate" :close-on-content-click="true" transition="scale-transition" offset-y
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small v-on="on" :disabled="dateStart == ''" v-bind="attrs" elevation="0"
                          color="rgb(63 230 117)" height="30px" class="">
                          <v-icon small color="white">mdi-calendar</v-icon>
                        </v-btn>
                      </template>
                      <v-date-picker :min="dateStart" :max="nowDate" locale="id" type="date" color="rgb(63 230 117)"
                        @change="countDays" v-model="dateEnd"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-col>
              </v-col>
            </v-col>
            <v-col cols="12" sm="2" class="py-0">
              <v-col cols="12" class="d-flex flex-wrap justify-center px-0">
                <v-col cols="12" class="pa-0">
                  <div>Period</div>
                </v-col>
                <v-col cols="12" class="px-0 ">
                  <h4 class="grey--text lighten-2">{{ period }} days</h4>
                </v-col>
              </v-col>
            </v-col>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <h3 class="grey--text pt-2 pb-1">Overview</h3>
    <v-row>
      <v-col v-for="(views, idx) in overview" :key="idx">
        <v-card color="grey lighten-3" elevation="0" class="rounded-lg pa-3">
          <v-row align="center">
            <v-col cols="8" sm="8">
              <h3>{{ views.count }}</h3>
            </v-col>
            <v-col cols="4" sm="4" class="d-flex justify-end">
              <div color="grey lighten-5" class="rounded-circle d-flex justify-center grey lighten-5"
                style="max-width: 30px; max-height: 30px">
                <v-icon color="green">{{ views.icon }}</v-icon>
              </div>
            </v-col>
          </v-row>
          <div class="grey--text">{{ views.label }}</div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" sm="6" lg="6" class="py-5">
        <v-col class="d-flex flex-wrap align-center px-0">
          <v-col cols="12" sm="6" lg="6" class="pa-0">
            <div class="grey--text text--darken-1 pr-2">
              Message Credit : Rp 100.000
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="6" class="pa-0">
            <v-btn class="btn-text" color="rgb(63 230 117)">
              <div class="white--text">
                Buy Credit
              </div>
            </v-btn>
          </v-col>
        </v-col>
      </v-col>
      <v-col cols="12" sm="6" lg="6" class="py-5 ">
        <v-col class="d-flex flex-wrap align-center px-0">
          <v-col cols="12" sm="6" lg="6" class="pa-0 d-flex justify-start justify-sm-end">
            <div class="grey--text text--darken-1 pr-2">
              Messaging Limit : 1000/24 hours
            </div>
          </v-col>
          <v-col cols="12" sm="6" lg="6" class="pa-0 d-flex justify-start justify-sm-end">
            <v-btn class=" btn-text" color="rgb(63 230 117)">
              <div class="white--text">
                New Broadcast
              </div>
            </v-btn>
          </v-col>
        </v-col>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" lg="12" class="grey lighten-2 py-3 px-0 mt-5">
        <v-col sm="12">
          <v-col class="d-flex flex-wrap align-center">
            <v-col class="text-left">
              <h3>Broadcast List</h3>
            </v-col>
            <h5>Sorted By :</h5>
            <v-col cols="12" style="max-width: 300px">
              <v-select :items="sorting" label="Sorting" color="rgb(63 230 117)" outlined dense hide-details="auto"
                class="white">
              </v-select>
            </v-col>
            <v-col cols="12" style="max-width: 300px">
              <v-text-field label="Search" outlined dense hide-details="auto" class="white" append-icon="mdi-magnify"
                color="rgb(63 230 117)">
              </v-text-field>
            </v-col>
          </v-col>
        </v-col>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-none d-md-block">
        <v-simple-table class="white-row ">
          <template v-slot:default>
            <thead class="">
              <tr>
                <th class="text-left" width="20%"><span class="" style="font-size: 15px;  font-weight: bold;">Broadcast
                    Name</span></th>
                <th class="text-left" width="15%"><span class=""
                    style="font-size: 15px;  font-weight: bold;">Scheduled</span></th>
                <th class="text-center" width="5%"><span class=""
                    style="font-size: 15px;  font-weight: bold;">Successfull</span></th>
                <th class="text-center" width="5%"><span class=""
                    style="font-size: 15px;  font-weight: bold;">Read</span></th>
                <th class="text-center" width="5%"><span class=""
                    style="font-size: 15px;  font-weight: bold;">Replied</span></th>
                <th class="text-left" width="10%"><span class=""
                    style="font-size: 15px;  font-weight: bold;">Recipients</span></th>
                <th class="text-left" width="10%"><span class=""
                    style="font-size: 15px;  font-weight: bold;">Failed</span></th>
                <th class="text-center" width="10%"><span class=""
                    style="font-size: 15px;  font-weight: bold;">Status</span></th>
                <th class="text-right" width="20%"><span class=""
                    style="font-size: 15px;  font-weight: bold;">Actions</span></th>
              </tr>
            </thead>
            <tbody class="grey--text text--darken-3">
              <tr v-for="(broadcast, idx) in broadcast_list" :key="idx">
                <td class="text-left">{{ broadcast.broadcast_name }}</td>
                <td class="text-left py-2">
                  <div class="font-weight-500">{{ broadcast.broadcast_scheduled }}</div>
                </td>
                <td class="text-center py-2">
                  <v-progress-circular :rotate="-90" :size="50" width="6"
                    :color="progressColor(broadcast.broadcast_success)" :value="broadcast.broadcast_success"
                    class="pa-2 font-weight-500">
                    <span class="grey--text">
                      {{ broadcast.broadcast_success }}%
                    </span>
                  </v-progress-circular>
                </td>
                <td class="text-center py-2">
                  <v-progress-circular :rotate="-90" :size="50" width="6"
                    :color="progressColor(broadcast.broadcast_read)" :value="broadcast.broadcast_read"
                    class="font-weight-500">
                    <span class="grey--text">
                      {{ broadcast.broadcast_read }}%
                    </span>
                  </v-progress-circular>
                </td>
                <td class="text-center py-2">
                  <v-progress-circular :rotate="-90" :size="50" width="6"
                    :color="progressColor(broadcast.broadcast_replied)" :value="broadcast.broadcast_replied"
                    class="font-weight-500">
                    <span class="grey--text">
                      {{ broadcast.broadcast_replied }}%
                    </span>
                  </v-progress-circular>
                </td>
                <td class="text-left py-2">
                  <div class="font-weight-500">{{ broadcast.broadcast_recipient }} Contact</div>
                </td>
                <td class="text-left py-2">
                  <div class="font-weight-500">{{ broadcast.broadcast_failed }} Contact</div>
                </td>
                <td class="text-center">
                  <v-chip small class="font-weight-bold white--text text-uppercase" color="rgb(63 230 117)">{{
                      broadcast.broadcast_status
                  }}</v-chip>
                </td>
                <td class="text-right py-2">
                  <v-row justify="end">
                    <v-btn small outlined color="grey" class="mx-1">
                      <v-icon dark>
                        mdi-poll
                      </v-icon>
                    </v-btn>
                    <v-btn small outlined color="grey" class="mx-1">
                      <v-icon dark>
                        mdi-eye-outline
                      </v-icon>
                    </v-btn>
                  </v-row>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col class="d-block d-md-none px-0">
        <v-card elevation="0" class="rounded-lg my-1" color="grey lighten-4" v-for="(broadcast, idx) in broadcast_list"
          :key="idx">
          <v-col>
            <div class="d-flex align-center justify-space-between mb-1">
              <span class="text--list-item grey--text">Broadcast Name</span>
              <h4 class="text--list-item font-weight-500">{{ broadcast.broadcast_name }}</h4>
            </div>
            <div class="d-flex align-center justify-space-between mb-1">
              <span class="text--list-item grey--text">Scheduled</span>
              <span class="text--list-item font-weight-500">{{ broadcast.broadcast_scheduled | datetime }}</span>
            </div>
            <div class="d-flex flex-wrap align-center justify-space-between mb-1">
              <v-col cols="6" class="d-flex pa-0">
                <div class="text--list-item grey--text mr-1">
                  Recipients :
                </div>
                <h5 class="text--list-item font-weight-600">
                  {{ broadcast.broadcast_recipient }} Contact{{ broadcast.broadcast_recipient > 1 ? 's' : '' }}
                </h5>
              </v-col>
              <v-col cols="6" class="d-flex pa-0 justify-end">
                <div class="text--list-item text-right grey--text mr-1">
                  Failed :
                </div>
                <h5 class="text--list-item font-weight-600 text-right">
                  {{ broadcast.broadcast_failed }} Contact{{ broadcast.broadcast_failed > 1 ? 's' : '' }}
                </h5>
              </v-col>
            </div>
            <div class="d-flex align-center justify-space-between mb-1">
              <span class="text--list-item grey--text">Status</span>
              <h5 class="text--list-item font-weight-500 green--text text--lighten-2">{{ broadcast.broadcast_status }}
              </h5>
            </div>
            <div class="d-flex flex-wrap align-center justify-space-between mb-1">
              <v-col cols="4" class="d-flex flex-wrap align-center justify-center">
                <v-col cols="12" class="pa-0">
                  <div class="text-center text--list-item">
                    Successfull
                  </div>
                </v-col>
                <v-progress-circular :rotate="-90" :size="50" width="6"
                  :color="progressColor(broadcast.broadcast_success)" :value="broadcast.broadcast_success"
                  class="pa-2 font-weight-500">
                  <span class="grey--text">
                    {{ broadcast.broadcast_success }}%
                  </span>
                </v-progress-circular>
              </v-col>
              <v-col cols="4" class="d-flex flex-wrap align-center justify-center">
                <v-col cols="12" class="pa-0">
                  <div class="text-center text--list-item">
                    Read
                  </div>
                </v-col>
                <v-progress-circular :rotate="-90" :size="50" width="6" :color="progressColor(broadcast.broadcast_read)"
                  :value="broadcast.broadcast_read" class="font-weight-500">
                  <span class="grey--text">
                    {{ broadcast.broadcast_read }}%
                  </span>
                </v-progress-circular>
              </v-col>
              <v-col cols="4" class="d-flex flex-wrap align-center justify-center">
                <v-col cols="12" class="pa-0">
                  <div class="text-center text--list-item">
                    Replied
                  </div>
                </v-col>
                <v-progress-circular :rotate="-90" :size="50" width="6"
                  :color="progressColor(broadcast.broadcast_replied)" :value="broadcast.broadcast_replied"
                  class="font-weight-500">
                  <span class="grey--text">
                    {{ broadcast.broadcast_replied }}%
                  </span>
                </v-progress-circular>
              </v-col>
            </div>
            <div class="d-flex flex-wrap align-center justify-space-between mb-1">
              <v-btn small outlined color="grey" class="mx-1">
                <v-icon dark>
                  mdi-poll
                </v-icon>
              </v-btn>
              <v-btn small outlined color="grey" class="mx-1">
                <v-icon dark>
                  mdi-eye-outline
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Dashboard',
  components: {
  },
  filters: {
    datetime: function (date) {
      return moment(date).format("DD MMM YYYY HH:mm")
    }
  },
  data() {
    return {
      nowDate: new Date().toISOString().slice(0, 10),
      startDate: false,
      dateStart: '',
      endDate: false,
      dateEnd: '',
      period: 0,
      overview: [
        {
          count: 12,
          label: 'Sent',
          icon: 'mdi-check'
        },
        {
          count: 12,
          label: 'Delivered',
          icon: 'mdi-check-all'
        },
        {
          count: 11,
          label: 'Read',
          icon: 'mdi-eye-outline'
        },
        {
          count: 12,
          label: 'Replied',
          icon: 'mdi-arrow-left-top'
        },
        {
          count: 0,
          label: 'Sending',
          icon: 'mdi-send'
        },
        {
          count: 0,
          label: 'Failed',
          icon: 'mdi-close-circle-outline'
        },
        {
          count: 0,
          label: 'Proccessing',
          icon: 'mdi-sync'
        },
        {
          count: 0,
          label: 'Queued',
          icon: 'mdi-human-queue'
        },
      ],
      sorting: [
        'Last Updated'
      ],
      broadcast_list: [
        {
          broadcast_name: 'test',
          broadcast_scheduled: '2021-07-16 12:23',
          broadcast_success: 50,
          broadcast_read: 0,
          broadcast_replied: 0,
          broadcast_recipient: 1,
          broadcast_failed: 1,
          broadcast_status: 'Completed',
        },
        {
          broadcast_name: 'test_broadcast_1',
          broadcast_scheduled: '2021-07-16 12:23',
          broadcast_success: 70,
          broadcast_read: 62,
          broadcast_replied: 0,
          broadcast_recipient: 1,
          broadcast_failed: 1,
          broadcast_status: 'Completed',
        },
        {
          broadcast_name: 'test_broadcast_2',
          broadcast_scheduled: '2021-07-16 12:23',
          broadcast_success: 30,
          broadcast_read: 30,
          broadcast_replied: 100,
          broadcast_recipient: 1,
          broadcast_failed: 1,
          broadcast_status: 'Completed',
        },
        {
          broadcast_name: 'test_broadcast_3',
          broadcast_scheduled: '2021-07-16 12:23',
          broadcast_success: 100,
          broadcast_read: 90,
          broadcast_replied: 0,
          broadcast_recipient: 1,
          broadcast_failed: 1,
          broadcast_status: 'Completed',
        }
      ]
    }
  },
  methods: {
    progressColor(value) {
      let color
      if (value < 50) {
        color = "red"
      } else if (value < 75) {
        color = "orange"
      } else {
        color = 'rgb(63 230 117)'
      }
      return color
    },
    countDays() {
      let difference = new Date(this.dateStart) - new Date(this.dateEnd)
      let TotalDays = (Math.ceil(difference / (1000 * 3600 * 24))) * -1;
      this.period = TotalDays
      console.log(TotalDays);
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-text {
  text-transform: unset !important;
}

.text--list-item {
  font-size: 13px;
}
</style>

<template>
	<div id="contact">
		<Datatable ref="datatable" v-bind:table="table" v-on:add="addContact" v-on:import="importContact"
			v-on:update="updateContact" v-on:delete="deleteContact" />
		<v-dialog v-model="dialogAdd.show" max-width="500">
			<v-card>
				<v-card-title style="background-color:#00E676;">
					<span class="text-h6 white--text">{{ this.dialogAdd.title }}</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-text-field v-model="form.name" :error-messages="validation.name"
									:rules="rules.nameRules" color="#00E676" outlined label="Name" dense></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field v-model="form.phonenumber" :error-messages="validation.phonenumber" dense
									color="#00E676" outlined label="Phone Number" type="number"
									:rules="rules.noTelpRules"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-textarea v-model="form.address" :error-messages="validation.address" dense
									color="#00E676" outlined label="Address">
								</v-textarea>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-row align="center" justify="end" class="mb-5 mr-5">
						<v-btn @click="dialogAdd.show = false" class="mr-2">
							Close
						</v-btn>
						<v-btn color="#00E676" dark @click="saveContact()">
							Save
						</v-btn>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogImport.show" max-width="500">
			<v-card>
				<v-card-title style="background-color:#00E676;">
					<span class="text-h6 white--text">{{ this.dialogImport.title }}</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-file-input outlined dense color="#00E676" id="uploadIdentity"
									accept="application/vnd.ms-excel" prepend-icon="mdi-cloud-upload-outline"
									label="Pilih File" v-model="form.fileUpload" :rules="rules.fileUpload">
								</v-file-input>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-row align="center" justify="end" class="mb-5 mr-5">
						<v-btn @click="dialogImport.show = false" class="mr-2">
							Close
						</v-btn>
						<v-btn color="#00E676" dark>
							Save
						</v-btn>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Datatable from '@/components/Datatable'

export default {
	name: 'Contact',
	components: {
		Datatable
	},
	data() {
		return {
			dialogAdd: {
				show: false,
				title: '',
				action: '',
			},
			dialogImport: {
				show: false,
				title: '',
				action: '',
			},
			rules: {
				fileUpload: [
					v => !!v || 'File contact tidak boleh kosong',
					v => (v && v.size > 0) || 'File contact tidak boleh kosong',
				],
				nameRules: [
					v => !!v || 'Name cannot be empty',
				],
				noTelpRules: [
					v => !!v || 'Phone Number cannot be empty',
					v => v.length > 10 || 'Minimum 10 characters'
				],
			},
			table: {
				url: '/api/v2/contact',
				keys: 'id',
				height: '330px',
				limit: 10,
				right: [
					// {
					// 	icon: 'mdi-database-import-outline',
					// 	title: 'Import',
					// 	class: 'mr-2',
					// 	color: '#00E676',
					// 	action: 'import',
					// },
					{
						icon: 'mdi-plus',
						title: 'Add',
						class: 'mr-2',
						color: '#00E676',
						action: 'add',
					},
				],
				config: [{
					text: 'Action',
					class: 'text-center',
					action: true,
					width: '200px',
					value: [{
						icon: 'mdi-pencil',
						class: 'mr-2',
						color: 'warning',
						action: 'update',

					},
					{
						icon: 'mdi-delete',
						class: '',
						color: 'error',
						action: 'delete',
					}
					],
				},
				{
					text: 'Name',
					class: 'text-left',
					width: '400px',
					value: 'name',
				},
				{
					text: 'Phone Number',
					class: 'text-left',
					width: '200px',
					value: 'phonenumber'
				},
				{
					text: 'Address',
					class: 'text-left',
					value: 'address'
				},
				],
				filter: [{
					label: 'Name',
					type: 'text',
					value: 'name'
				},
				{
					label: 'Phone Number',
					type: 'text',
					value: 'phonenumber'
				},
				{
					label: 'Address',
					type: 'text',
					value: 'address'
				},
					// {
					// 	label: 'Selekk',
					// 	type:'select',
					// 	value:'select',
					// 	options: [
					// 		{
					// 			label: 'Yes',
					// 			value: 'Y',
					// 		},
					// 		{
					// 			label: 'No',
					// 			value: 'N'
					// 		}
					// 	]
					// },
					// {
					// 	label: 'Date',
					// 	type:'date',
					// 	value:'date'
					// }
				],
			},
		}
	},

	computed: {
		form() {
			return this.$store.state.contact.form
		},
		validation() {
			return this.$store.state.validation.data
		},
	},
	mounted() {
		// Refresh Datatable
		this.$refs.datatable.fetchDataTable();
	},
	methods: {
		addContact() {
			this.resetValidation();
			this.$store.state.contact.form = {
				name: '',
				phonenumber: '',
				address: ''
			}
			this.dialogAdd.show = true;
			this.dialogAdd.title = "Add Contact";
			this.dialogAdd.action = 'save';
		},
		importContact() {
			this.dialogImport.show = true;
			this.dialogImport.title = "Import Contact";
			this.dialogImport.action = 'save';
		},
		updateContact(value) {
			this.resetValidation();
			this.$store.dispatch('getContactById', {
				id: value
			})
			this.dialogAdd.show = true;
			this.dialogAdd.title = "Update Contact";
			this.dialogAdd.action = 'update';
		},
		async saveContact() {
			this.resetValidation();
			let action = this.dialogAdd.action == 'save' ? 'createContact' : 'updateContact';
			let success = await this.$store.dispatch(action, this.$store.state.contact.form)

			if (success) {
				this.dialogAdd.show = false
				this.$swal.fire({
					icon: 'success',
					title: 'Success ' + this.dialogAdd.action + ' Contact',
					showConfirmButton: false,
					timer: 1500
				})
				this.$refs.datatable.fetchDataTable();
			}
		},
		deleteContact(value) {
			this.$swal.fire({
				title: 'Apakan anda yakin?',
				text: "Proses ini tidak bisa dikembalikan!!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('deleteContact', {
						id: value
					})
					this.$swal.fire({
						icon: 'success',
						title: 'Berhasil hapus kontak!!',
						showConfirmButton: false,
						timer: 1500
					}).then(() => {
						this.$refs.datatable.fetchDataTable();
					})
				}
			})
		},
		resetValidation() {
			this.$store.state.validation = {
				success: true,
				message: '',
				error_code: '',
				data: {}
			}
		},
	},
}
</script>
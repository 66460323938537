import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import {
	router
} from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Axios from 'axios'
import swal from 'sweetalert2';

import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';

const socket = io(process.env.VUE_APP_API_URL);

Vue.use(VueSocketIOExt, socket);

Vue.use({
	install(Vue) {
		Vue.swal = swal
		Vue.prototype.$swal = swal
	}
})

Vue.config.productionTip = false
Vue.prototype.$http = Axios;

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
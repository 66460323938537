import axios from "axios";
import {
    getToken
} from "@/service/token";
// import router from "@/router/index.js";

const {
    VUE_APP_API_URL
} = process.env;

export function request(method, url, config = {}, options = {}) {
    const {
        params,
        data,
        headers,
        maxContentLength
    } = config;

    // non-axios specific params
    const {
        suppressAuth
    } = options;

    const baseURL = VUE_APP_API_URL;

    // @see: https://tools.ietf.org/html/rfc6750
    const Token = `${getToken()}`;


    return new Promise(resolve => {
        axios({
            method,
            baseURL,
            url,
            params,
            data,
            headers: suppressAuth ?
                headers :
                {
                    ...headers,
                    "Access-Control-Allow-Origin": "*",
                    Authorization: 'Bearer ' + Token,
                },
            maxContentLength
        })
            .then(response => {
                resolve({
                    ...response,
                    data: response.data
                });
            })
            .catch(err => {
                if (err.response.status == 401) {
                    TokenService.removeToken();
                    window.location.href = "/dashboard/";
                } else if (err.response.status == 500) {
                    // router.push("/error");
                }
            });
    });
}

export function get(url, config, options) {
    return request("GET", url, config, options);
}

export function post(url, config, options) {
    return request("POST", url, config, options);
}

export function put(url, config, options) {
    return request("PUT", url, config, options);
}

// not "delete()" because of reserved word
export function destroy(url, config, options) {
    return request("DELETE", url, config, options);
}
export default {
    user: {
        user: {},
        list: [],
        form: {
            user_name: '',
            user_username: '',
            user_phonenumber: '',
            user_email: '',
            user_birth_place: '',
            user_birth_date: '',
            user_address: '',
        },
    },

    outbox: {
        form: {
            message_media: '',
            message_worker_session_name: '',
            message_message_content: '',
            message_receiver_phonenumber: ''
        },

        broadcastGroup: {
            session_name: '',
            group_id: '',
            message_content: ''
        }
    },

    contact: {
        option: [],
        list: [],
        form: {
            name: '',
            phonenumber: '',
            address: '',
            fileUpload: ''
        },
    },

    group: {
        list: [],
        form: {
            group_name: ''
        },
        formContact: {
            group_privilege_group_id: '',
            group_privilege_contact_id: ''
        }
    },

    message: {
        list: [],
        form: {
            token: '',
            content: '',
            phonenumber: '',
        }
    },

    worker: {
        option: [],
        list: [],
        form: {
            label: ''
        }
    },

    socket: {
        isConnected: false,
        socketMessage: '',
        listWorker: []
    },

    validation: {
        success: false,
        message: '',
        error_code: '',
        data: {}
    },

    loading: {
        isLoading: false,
        fullPage: true,
        height: 150,
        width: 150,
        shape: 'dots',
        color: "#28fc03",
    },
}
<template>
	<div id="group">
		<Datatable ref="datatable" v-bind:table="table" typeMessage="logMessage" />
	</div>
</template>

<script>
	import Datatable from '@/components/Datatable'
	export default {
		name: 'User',
		components: {
			Datatable
		},
		data() {
			return {
				dialog: {
					show: false,
					title: '',
					action: '',
				},
				table: {
					url: '/api/v2/message',
					keys: 'id',
					height: '100%',
					limit: 10,
					config: [
						{
							text: 'Aksi',
							action: true,
							value: [{
									icon: 'mdi-refresh',
									class: 'mr-2',
									color: 'warning',
									action: 'update',
								  
								},
							],
						},
						// {
						// 	text: 'Kode Pengirim',
						// 	class: '',
						// 	value: 'worker_session_name',
						// },
						{
							text: 'Receiver Number',
							value: 'phonenumber'
						},
						{
							text: 'Content',
							class: '',
							value: 'content',
						},
						{
							text: 'Status Pesan',
							value: 'status'
						},
					],
					filter: [
						// {
						// 	label: 'Kode Pengirim',
						// 	type: 'text',
						// 	value: 'worker_session_name'
						// },
						{
							label: 'Receiver Number',
							type: 'text',
							value: 'phonenumber'
						},
					]
				},
			}
		},

		computed: {
			message() {
				return this.$store.state.message.list
			},
		},
		mounted() {
			this.$store.dispatch('getListMessage')
		},

		methods: {
			
		}

	}
</script>
<template>
    <v-app>
        <v-main>
            <v-container fill-height :fluid="this.$vuetify.breakpoint.name =='xs'">
                <v-row align="center" justify="center">
                    <v-col cols="12" xs="12" sm="6" v-show="this.$vuetify.breakpoint.name !=='xs'">
                        <v-row>
                            <v-col class="pl-10 pb-0" cols="9">
                                <p class="text-h4 text--primary font-weight-medium">
                                    Register to <br>
                                    wa<span class="green--text">One</span>
                                </p>
                            </v-col>
                            <v-col class="pl-10" cols="5">
                                <p class=" text--primary font-weight-medium">
                                    If you have an account <br>
                                    you can <a class="green--text" @click="login()">Sign In!</a>
                                </p>
                            </v-col>
                            <v-col cols="5">
                                <v-img src="@/assets/ilustrasi-sign-up.svg"></v-img>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-container>
                                <v-row align="center" justify="center">
                                    <v-col cols="12" sm="6" class="py-0">
                                        <v-text-field rounded single-line background-color="rgb(230, 255, 246)"
                                            v-model="forms.name" :rules="nameRules" color="black"
                                            class="green--text" label="Full Name" solo>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="py-0">
                                        <v-text-field rounded single-line background-color="rgb(230, 255, 246)"
                                            :error-messages="validation.email" v-model="forms.email"
                                            :rules="emailRules" color="black" class="green--text" label="Email" solo>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="py-0">
                                        <v-text-field rounded single-line background-color="rgb(230, 255, 246)"
                                            :error-messages="validation.phonenumber" type="number"
                                            v-model="forms.phonenumber" :rules="noTelpRules" color="black"
                                            class="green--text" label="Phone Number" solo>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="py-0">
                                        <v-text-field rounded single-line background-color="rgb(230, 255, 246)"
                                            v-model="forms.birthPlace" :rules="birthPlaceRules" color="black"
                                            class="green--text" label="Birth Place" solo>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="py-0">
                                        <v-menu
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    rounded
                                                    single-line background-color="rgb(230, 255, 246)"
                                                    v-model="forms.birthDate"
                                                    label="Birth Date"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    solo
                                                    :rules="birthDateRules"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="forms.birthDate"
                                                @input="menu2 = false"
                                                color="green"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="py-0">
                                        <v-text-field rounded single-line v-model="forms.password"
                                            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" :rules="passwordRules"
                                            :type="showPass ? 'text' : 'password'" @click:append="showPass = !showPass"
                                            background-color="rgb(230, 255, 246)" color="black" class="green--text"
                                            label="Password" solo>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" class="py-0">
                                        <v-textarea rounded single-line rows="4" background-color="rgb(230, 255, 246)"
                                            v-model="forms.address" color="black"
                                            class="green--text" label="Address" solo>
                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="12" sm="12" class="py-0">
                                        <v-btn class="pa-2 white--text" width="100%" color="green" rounded
                                            @click="register()">
                                            Register
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    export default {
        data() {
            return {
                date: '',
                menu2: false,
                valid: true,
                forms: {
                    name: '',
                    phonenumber: '',
                    email: '',
                    birthPlace: '',
                    birthDate: '',
                    address: '',
                    password: '',
                },

                nameRules: [
                    v => !!v || 'Full Name cannot be empty',
                ],

                userNameRules: [
                    v => !!v || 'Username cannot be empty',
                    v => (v.split(' ').length <= 1) || 'Username cannot contain spaces'
                ],

                noTelpRules: [
                    v => !!v || 'Phone Number cannot be empty',
                    v => v.length > 10 || 'Minimum 10 characters'
                ],

                emailRules: [
                    v => !!v || 'Email cannot be empty',
                    v => /.+@.+\..+/.test(v) || 'Email not valid',
                ],

                birthPlaceRules: [
                    v => !!v || 'Birth Place cannot be empty'
                ],

                birthDateRules: [
                    v => !!v || 'Birth Date cannot be empty'
                ],

                showPass: false,
                passwordRules: [
                    v => !!v || 'Password cannot be empty',
                    v => v.length >= 5 || 'Password length min 5 character',
                    v => v.match(/\d+/g) || 'Password must contain letters'
                ],
            }
        },

        computed: {
            validation() {
                return this.$store.state.validation.data
            },
        },

        mounted() {
            this.$store.state.validation = {
                success: true,
                message: '',
                error_code: '',
                data: {}
            }
        },

        methods: {
            login() {
                this.$router.push('/login')
            },
            register() {
                if (this.$refs.form.validate()) {
                    this.forms.username = this.forms.email
                    this.$http.post(`${process.env.VUE_APP_API_URL}/api/v2/auth/register`, this.forms)
                        .then(response => {
                            if (response.data.error_code == "VALIDATION_ERROR") {
                                this.$store.state.validation = {
                                    success: false,
                                    message: response.data.message,
                                    error_code: response.data.error_code,
                                    data: response.data.data
                                }
                            }
                            if (response.data.success == 200) {
                                this.$swal.fire({
                                    title: 'Registration Success. Please Login!!',
                                    icon: 'success',
                                    showCancelButton: false,
                                    confirmButtonColor: '#00E676',
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.$router.push('/login')
                                    }
                                })
                            }
                        }).catch(async (error) => {
                            console.log(error);
                        })
                }
            }
        }
    }
</script>
<template>
  <v-col style="height: 100%">
  </v-col>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Template',
  components: {
  },
  filters: {
    datetime: function(date) {
      return moment(date).format("DD MMM YYYY HH:mm")
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .btn-text {
    text-transform: unset !important;
  }
  .text--list-item {
    font-size: 13px;
  }
</style>

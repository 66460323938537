<template>
	<v-container fluid>
		<v-row>
			<v-col>
				<h1 class="ml-5" style="color: rgb(63 230 117);">Users</h1>
			</v-col>
			<v-col>
				<v-card class="d-flex justify-end mr-4" flat>
					<v-breadcrumbs color="green" :items="bread"></v-breadcrumbs>
				</v-card>
			</v-col>
		</v-row>

		<v-dialog transition="dialog-top-transition" max-width="600" scrollable v-model="modal">
			<v-card>
				<v-card-title>
					<span class="text-h5">{{title}} Users</span>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-text-field label="Nama Lengkap" required v-model="form.user_name"></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="Username" hint="minimal 6 karakter" v-model="form.user_username">
								</v-text-field>
							</v-col>
							<v-col cols="12" sm="6">
								<v-text-field label="Password" hint="minimal 8 karakter"
									:append-icon="pass ? 'mdi-eye' : 'mdi-eye-off'" :type="pass ? 'text' : 'password'"
									counter @click:append="pass = !pass">
								</v-text-field>
							</v-col>
							<v-col cols="12" sm="6">
								<v-text-field label="Password Confirm"
									:append-icon="passconf ? 'mdi-eye' : 'mdi-eye-off'"
									:type="passconf ? 'text' : 'password'" counter @click:append="passconf = !passconf">
								</v-text-field>
							</v-col>
							<v-col cols="12" sm="6">
								<v-text-field label="No. Telpon" required></v-text-field>
							</v-col>
							<v-col cols="12" sm="6">
								<v-text-field label="Email" required></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-textarea label="Alamat" required></v-textarea>
							</v-col>
							<v-col cols="12" sm="6">
								<v-text-field label="Tempat Lahir" required></v-text-field>
							</v-col>
							<v-col cols="12" sm="6">
								<v-menu ref="menu" v-model="menu" :close-on-content-click="false"
									:return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="date" label="Tanggal Lahir" prepend-icon="mdi-calendar"
											readonly v-bind="attrs" v-on="on">
										</v-text-field>
									</template>
									<v-date-picker v-model="date" no-title scrollable>
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="menu = false">
											Cancel
										</v-btn>
										<v-btn text color="primary" @click="$refs.menu.save(date)">
											OK
										</v-btn>
									</v-date-picker>
								</v-menu>
							</v-col>
						</v-row>
					</v-container>
					<small>*Harus di isi</small>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="modal = false">
						Close
					</v-btn>
					<v-btn color="blue darken-1" text @click="modal = false">
						Save
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-row>
			<v-col>
				<v-data-table :headers="headers" :items="user" :items-per-page="5" class="elevation-1">
					<template v-slot:item.actions="{item}">
						<v-chip color="warning" dark @click="getDetail(item)">
							<v-icon small class="mx-1">
								mdi-pencil
							</v-icon>
						</v-chip>
						<v-chip color="error" dark class="ml-2" @click="alert(item)">
							<v-icon class="mx-1" small>
								mdi-delete
							</v-icon>
						</v-chip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		name: 'User',
		data() {
			return {
				date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				menu: false,
				pass: false,
				passconf: false,
				bread: [{
						text: process.env.VUE_APP_BRAND,
						disabled: false,
						href: 'breadcrumbs_dashboard',
					},
					{
						text: 'Users',
						disabled: true,
						href: 'breadcrumbs_link_1',
					},
				],
				headers: [{
						text: 'Nama',
						align: 'start',
						sortable: false,
						value: 'user_name',
					},
					{
						text: 'Username',
						value: 'user_username'
					},
					{
						text: 'No Telpon',
						value: 'user_phonenumber'
					},
					{
						text: 'Email',
						value: 'user_email'
					},
					{
						text: 'Role',
						value: 'user_role'
					},
					{
						text: 'Alamat',
						value: 'user_address'
					},
					{
						text: 'Actions',
						value: 'actions',
						align: 'center',
						sortable: false
					},
				],
				desserts: [],
				modal: false,
				title: ''
			}
		},

		computed: {
			user() {
				return this.$store.state.user.list
			},
			form() {
				return this.$store.state.user.form
			}
		},
		mounted() {
			this.$store.dispatch('getLisUser')
		},

		methods: {
			getDetail(item) {
				this.$store.dispatch('getUserById', {
					id: item.user_id
				})
				this.modal = true
				this.title = 'Edit'
			},
		}

	}
</script>
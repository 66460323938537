<template>
  <v-col class="pa-0" style="height: 100%">
    <v-card color="white" elevation="0">
      <div class="d-flex flex-wrap">
        <v-col cols="12" lg="6" md="6" class="pa-3">
          <v-row align="center">
            <v-col lg="2" md="2">
              <h4>Bearer token</h4>
            </v-col>
            <v-col lg="6" md="6">
              <v-text-field
                outlined
                dense
                hide-details="auto"
                class="mr-1"
                color="green accent-3"
                v-model="token">
              </v-text-field>
            </v-col>
            <v-col lg="3" md="3">
              <v-btn outlined color="green accent-3" class="btn--text">
                Copy
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col lg="2" md="2">
              <h4>API Endpoint</h4>
            </v-col>
            <v-col lg="6" md="6">
              <v-text-field
                outlined
                dense
                hide-details="auto"
                class="mr-1"
                color="green accent-3"
                v-model="endpoint">
              </v-text-field>
            </v-col>
            <v-col lg="3" md="3">
              <v-btn outlined color="green accent-3" class="btn--text">
                Copy
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" md="6" class="">
          <div class="d-flex justify-end">
            <v-btn outlined color="green accent-3" class="mr-2 btn--text">
              <div>
                Authorize
              </div>
            </v-btn>
            <v-btn color="green accent-3" class="btn--text">
              <div class="white--text">
                Download postman collection 
              </div>
            </v-btn>
          </div>
        </v-col>
      </div>
    </v-card>
    <template>
      <v-expansion-panels flat>
        <v-expansion-panel v-for="(api, idx) in apiData" :key="idx" class="my-1 rounded-lg" :class="api.method == 'GET' ? 'orange lighten-5' : 'blue lighten-5'">
          <v-expansion-panel-header class="elevation-0" disable-icon-rotate>
            <v-row align="center" class="px-3">
              <v-card :color="api.method == 'GET' ? 'orange' : 'blue lighten-2'" elevation="0" class="pa-2">
                <div class="">
                  {{api.method}}
                </div>
              </v-card>
              <v-col>
                <div>{{api.api}}</div>
              </v-col>
            </v-row>
            <template v-slot:actions>
              <v-icon color="grey">
                mdi-lock-outline
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h4>{{api.method == 'GET' ? 'Parameter' : 'Body'}}</h4>
            <v-col v-for="(param, idx) in api.details.parameter" :key="idx" class="d-flex flex-wrap">
              <v-col cols="12" md="6" lg="6" class="pa-0 d-flex flex-wrap align-center">
                <v-col md="2" lg="2" class="px-0">
                  <div>
                    {{param.label}} : 
                  </div>
                </v-col>
                <v-col md="4" lg="4" class="d-flex align-center">
                  <div class="grey--text">
                    {{param.value}}
                  </div>
                  <div class="grey--text ml-2 text--list-item">
                    {{param.note}}
                  </div>
                </v-col>
                <v-col md="6" lg="6" class="d-flex align-center">
                  <v-text-field
                    :label="param.label"
                    outlined
                    dense
                    hide-details="auto"
                    class="white"
                    color="green accent-3"
                    >
                  </v-text-field>
                </v-col>
              </v-col>
            </v-col>
            <h4>Response</h4>
            <v-col v-for="(response, idx) in api.details.response" :key="idx" class="d-flex flex-wrap">
              <v-col cols="12" md="6" lg="6" class="pa-0 d-flex flex-wrap">
                <v-col md="10" lg="10" class="pa-0 d-flex align-center">
                  <div class="grey--text">
                    {{response.value}}
                  </div>
                  <div class="grey--text ml-2 text--list-item">
                    [{{response.note}}]
                  </div>
                </v-col>
                <v-col lg="12" md="12">
                  <v-card elevation="0" color="grey lighten-5" class="pa-3 rounded-lg">
                    <pre>{{response.sample}}</pre>
                  </v-card>
                </v-col>
              </v-col>
            </v-col>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </v-col>
</template>

<script>
import moment from 'moment'

  export default {
    name: 'API Documentation',
    components: {
    },
    filters: {
      datetime: function(date) {
        return moment(date).format("DD MMM YYYY HH:mm")
      }
    },
    data() {
      return {
        token: 'klsdhfinjncjNJKNnxKNXKJNClnLZNXJlNJnJK',
        endpoint: 'https://wa-one.com/',
        period: 0,
        apiData: [
          {
            method: 'GET',
            api: '/api/v2/getMessage/[Phone Number]',
            details: {
              parameter: [
                {
                  label: 'phoneNumber',
                  value: '[reciever number]',
                  note: 'Input reciever number'
                },
              ],
              response: [
                {
                  label: 'Status',
                  value: '200',
                  note: 'Success',
                  sample: {
                    code: 200,
                    message: 'success',
                    data: [
                      {
                        message_id: 1,
                        message_value: 'helo',
                        message_status: 'delivered'
                      }
                    ]
                  }
                },
                {
                  label: 'Status',
                  value: '404',
                  note: 'Not found',
                  sample: {
                    code: 404,
                    message: 'not found',
                    data: {}
                  }
                },
              ]
            }
          },
          {
            method: 'GET',
            api: '/api/v2/getMessage/[Phone Number]',
            details: {
              parameter: [
                {
                  label: 'phoneNumber',
                  value: '[reciever number]',
                  note: 'Input reciever number'
                },
              ],
              response: [
                {
                  label: 'Status',
                  value: '200',
                  note: 'Success',
                  sample: {
                    code: 200,
                    message: 'success',
                    data: [
                      {
                        message_id: 1,
                        message_value: 'helo',
                        message_status: 'delivered'
                      }
                    ]
                  }
                },
                {
                  label: 'Status',
                  value: '404',
                  note: 'Not found',
                  sample: {
                    code: 404,
                    message: 'not found',
                    data: {}
                  }
                },
              ]
            }
          },
          {
            method: 'POST',
            api: '/api/v2/sendMessage',
            details: {
              parameter: [
                {
                  label: 'phoneNumber',
                  value: '[reciever number]',
                  note: 'Input reciever number'
                },
              ],
              response: [
                {
                  label: 'Status',
                  value: '200',
                  note: 'Success',
                  sample: {
                    code: 200,
                    message: 'success',
                    data: {
                      message_id: 1,
                      message_value: 'helo',
                      message_status: 'delivered'
                    }
                  }
                },
                {
                  label: 'Status',
                  value: '404',
                  note: 'Not found',
                  sample: {
                    code: 404,
                    message: 'not found',
                    data: {}
                  }
                },
              ]
            }
          },
          {
            method: 'POST',
            api: '/api/v2/sendMessage',
            details: {
              parameter: [
                {
                  label: 'phoneNumber',
                  value: '[reciever number]',
                  note: 'Input reciever number'
                },
              ],
              response: [
                {
                  label: 'Status',
                  value: '200',
                  note: 'Success',
                  sample: {
                    code: 200,
                    message: 'success',
                    data: {
                      message_id: 1,
                      message_value: 'helo',
                      message_status: 'delivered'
                    }
                  }
                },
                {
                  label: 'Status',
                  value: '404',
                  note: 'Not found',
                  sample: {
                    code: 404,
                    message: 'not found',
                    data: {}
                  }
                },
              ]
            }
          },
        ],
        sorting: [
          'Last Updated'
        ],
      }
    },
    methods: {
      
    }
  }
</script>

<style lang="scss" scoped>
  .btn--text {
    text-transform: unset !important;
  }
  .text--list-item {
    font-size: 13px;
  }
  .v-expansion-panel::before {
    box-shadow: none;
  }
</style>

<template>
    <div>
        <div class="text-right mb-10">
            <v-btn class="mx-2" @click="emitEvent(action.action)" v-for="action in table.right" :color="action.color"
                dark>
                <v-icon dark>
                    {{ action.icon }}
                </v-icon>
                {{ action.title }}
            </v-btn>
            <v-btn v-if="filters && pagination.totalData > 0" class="white--text" @click="dialog = true"
                color="#00E676">
                <v-icon>
                    mdi-magnify
                </v-icon>
            </v-btn>
        </div>

        <v-dialog v-model="dialog" max-width="500">
            <v-card>
                <v-card-title style="background-color:#00E676;">
                    <span class="text-h6 white--text">Search</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col v-for="filter in filters" cols="12">
                                <v-text-field v-if="filter.type == 'text'" dense color="#00E676" outlined
                                    :label="filter.label" v-model="form_filter[`${filter.value}`]" required>
                                </v-text-field>
                                <v-select color="#00E676" v-if="filter.type == 'select'" dense :items="filter.options"
                                    item-text='label' item-value='value' v-model="form_filter[`${filter.value}`]"
                                    outlined :label="filter.label"></v-select>
                                <v-menu v-if="filter.type == 'date'" v-model="menu[`${filter.value}`]"
                                    :close-on-content-click="false" :nudge-right="40" lazy transition="scale-transition"
                                    offset-y full-width max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field outlined :label="filter.label"
                                            :value="form_filter[`${filter.value}`]" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="en-in" :min="minDate" :max="maxDate"
                                        v-model="form_filter[`${filter.value}`]" no-title
                                        @input="menu[`${filter.value}`] = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <v-btn @click="dialog = false">
                        Close
                    </v-btn>
                    <v-btn color="#00E676" class="white--text" @click="fetchDataTable()">
                        SEARCH
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-chip color="#00E676" label v-for="chips in chips_filter" class="ma-2 white--text">
            {{ chips.label }}: {{ chips.value }}
        </v-chip>
        <v-btn v-if="chips_filter.length > 0" @click="resetFilter()" class="white--text" height="30px" color="#00E676">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-row>
            <v-col v-if="pagination.totalData > 0">
                <v-simple-table fixed-header :height="table.height">
                    <thead>
                        <tr>
                            <th v-for="head in header" :class="head.class" :width="head.width">
                                {{ head.text }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in body" :keys="item[`${table.keys}`]">
                            <td v-for="head in header">
                                <div :class="head.class" v-if="head.action">
                                    <v-btn v-for="button in head.value" small dark fab class="mx-2 my-1"
                                        @click="emitEvent(button.action, item[`${table.keys}`])" :color="button.color">
                                        <v-icon dark>
                                            {{ button.icon }}
                                        </v-icon>
                                    </v-btn>
                                </div>
                                <div :class="head.class" v-else>
                                    {{ item[`${head.value}`] }}
                                </div>

                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <v-pagination class="pt-10" v-model="page" color="#00E676" @input="changePage()"
                    :length="pagination.totalPage" :total-visible="visible">
                </v-pagination>
            </v-col>

            <v-col v-else align="center" justify="center">
                <v-img src="@/assets/no-data-ilus.svg" max-width="400">
                </v-img>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {
    get
} from '@/service/axios.js'


export default {
    name: 'DataTable',
    props: ['table', 'typeMessage'],
    data() {
        return {
            header: {},
            body: {},
            pagination: {
                totalData: 0,
                next: {},
                prev: {}
            },
            dialog: false,
            filters: [],
            form_filter: {},
            chips_filter: [],
            menu: {},
            page: 1,
            visible: 5,
            text: '',
        }
    },
    methods: {
        fetchDataTable(page = 1, limit = this.table.limit) {
            this.dialog = false;
            let string = "";

            Object.keys(this.form_filter).forEach((keys) => {
                string += `&${keys}[eq]=${this.form_filter[`${keys}`]}`
            });

            this.chips_filter = Object.keys(this.form_filter).map((keys) => {
                return {
                    label: this.table.config.find(o => o.value === keys).text,
                    value: this.form_filter[`${keys}`]
                }
            })

            get(`${this.table.url}?page=${page}&limit=${limit}${string}`)
                .then((response) => {
                    let data = response.data;

                    if (data.success) {
                        this.header = this.table.config
                        this.filters = this.table.filter
                        this.body = data.data
                        this.pagination = data.pagination
                    }
                })
                .catch((error) => {
                    console.log(error)
                })

            this.form_filter = {};
        },
        resetFilter() {
            this.form_filter = {};
            this.fetchDataTable();
        },
        changePage() {
            this.fetchDataTable(this.page)
        },
        emitEvent(ev, key_val) {
            this.$emit(ev, key_val)
        }
    },
    mounted() {
        this.fetchDataTable();
    }
}
</script>

<style >
.v-pagination {
    justify-content: right !important;
}
</style>
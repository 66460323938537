<template>
    <v-app>
        <v-row align="center" justify="center">
            <v-col cols="12" xs="12" sm="8" v-show="this.$vuetify.breakpoint.name !== 'xs'">
                <v-row style="margin:0 2em;">
                    <v-col class="pl-10 pb-0" cols="9">
                        <p class="font-size-2-half text--primary font-weight-medium">
                            Sign In to <br>
                            wa<span class="green--text">One</span>
                        </p>
                    </v-col>
                    <v-col class="pl-10" cols="4">
                        <p class="font-size-1-half text--primary font-weight-medium">
                            if you don't have an account <br>
                            you can <a class="green--text" @click="registrasi()">Register here</a>
                        </p>
                    </v-col>
                    <v-col cols="6">
                        <v-img src="@/assets/ilustrasi-login.svg"></v-img>
                    </v-col>
                </v-row>
            </v-col>
            <!-- <v-col cols="12" xs="12" sm="4">
                
            </v-col> -->
            <v-col cols="12" xs="12" sm="4">
                <v-form ref="forms" v-model="valid" lazy-validation>
                    <v-row style="margin:0 1em !important;">
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field v-model="form.email" rounded single-line background-color="rgb(230, 255, 246)"
                                color="black" class="green--text" label="Email" solo :rules="emailRules">
                            </v-text-field>
                            <v-text-field v-model="form.password" rounded single-line
                                background-color="rgb(230, 255, 246)" label="Password" solo
                                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" :rules="passwordRules"
                                :type="showPass ? 'text' : 'password'" @click:append="showPass = !showPass">
                            </v-text-field>
                            <p class="text-right pa-0 ma-0" v-if="false"> <a class="pa-0 ma-0 green--text">
                                    Recovery
                                    Password </a></p>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-btn @click="handleSubmit()" class="pa-2 white--text" width="100%" color="green" rounded>
                                Sign In
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row align="center" class="ma-5" v-if="false">
                        <v-divider></v-divider>&nbsp; Or continue with &nbsp;<v-divider></v-divider>
                    </v-row>
                    <v-row align="center" justify="center" v-if="false">
                        <v-col cols="4" sm="4" md="4">
                            <v-card width="70" @click="loginGoogle()">
                                <v-card-title class="justify-center">
                                    <v-img contain width="40" src="@/assets/google.svg"></v-img>
                                </v-card-title>
                            </v-card>
                        </v-col>
                        <v-col cols="4" sm="4" md="4">
                            <v-card width="65" @click="loginApple()">
                                <v-card-title class="justify-center">
                                    <v-img contain width="37" src="@/assets/apple.svg"></v-img>
                                </v-card-title>
                            </v-card>
                        </v-col>
                        <v-col cols="4" sm="4" md="4">
                            <v-card width="70" @click="loginFacebook()">
                                <v-card-title class="justify-center">
                                    <v-img contain width="40" src="@/assets/facebook.svg"></v-img>
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </v-app>
</template>



<script>
import {
    getToken,
    saveToken
} from '@/service/token.js';
import post from '@/service/axios.js'

export default {
    data() {
        return {
            form: {
                email: "",
                password: ""
            },
            showPass: false,
            passwordRules: [
                v => !!v || 'Password cannot be empty',
            ],
            emailRules: [
                v => !!v || 'E-mail cannot be empty',
                v => /.+@.+\..+/.test(v) || 'E-mail are not valid',
            ],
            valid: true,
        }
    },
    methods: {
        handleSubmit() {
            if (this.$refs.forms.validate()) {
                if (this.form.password.length > 0) {
                    this.$http.post(`${process.env.VUE_APP_API_URL}/api/v2/auth/login`, this.form)
                        .then(response => {
                            if (response.data.success) {
                                saveToken(response.data.data.token);
                                if (getToken() != null) {
                                    if (this.$route.params.nextUrl != null) {
                                        this.$router.push(this.$route.params.nextUrl)
                                    } else {
                                        this.$store.dispatch("getProfile")
                                        this.$router.push('/')
                                    }
                                }
                            }
                        })
                        .catch(async (error) => {
                            await this.$swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Email or password is wrong.',
                            })
                        });
                }
            }
        },
        registrasi() {
            this.$router.push('/registrasi')
        }
    }
}
</script>

<style scoped>
.v-main__wrap {
    margin: 0 auto o !important;
}

.font-size-2-half {
    font-size: 2.5rem;
}

.font-size-1-half {
    font-size: 1rem;
}
</style>

import {
    get,
    post
} from '@/service/axios.js'
import store from '../store'

//Module api auth
export const getProfile = ({
    commit
}) => {
    get('/api/v2/user/profile')
        .then(response => {
            commit('SET_USER', response.data.data)
        })
}

export const updateProfile = ({
    commit
}, data) => {
    return post('/api/v2/user/profile', {
        data,
    })
        .then(response => {
            let res = response.data;

            if (!res.success) {
                commit('SET_VALIDATION_ERROR', res)
                console.log('kesini');
                return false;
            }

            commit('SET_USER', res.data)
            return true;

        })
        .catch(err => {
            return false;
        })
}

export const updateImageProfile = ({
    commit
}, data) => {
    return post('/api/v2/user/picture', {
        data
    })
        .then(response => {
            let res = response.data;

            if (!res.success) {
                commit('SET_VALIDATION_ERROR', res)
                return false;
            }

            commit('SET_USER', res.data)
            return true;

        })
        .catch(err => {
            return false;
        })
}


// Module api message
export const getListMessage = ({
    commit
}) => {
    get('/api/v2/message')
        .then(response => {
            commit('SET_LIST_MESSAGE', response.data.data)
        })
}

export const sendMessage = ({
    commit
}, data) => {
    return post('api/v2/worker/send', {
        data
    })
        .then(response => {
            let res = response.data;

            if (response.data.success == false) {
                commit('SET_VALIDATION_ERROR', res)

                return false;
            }

            return true;

        })
        .catch(err => {
            return false;
        })
}

// Module api contact

export const getListContact = ({
    commit
}) => {
    get('/api/v2/contact')
        .then(response => {
            commit('SET_LIST_CONTACT', response.data.data)
        })
}

export const getOptionContact = ({
    commit
}) => {
    return get('/api/v2/contact/option')
        .then(response => {
            let res = response.data;

            if (res.success) {
                commit('SET_OPTION_CONTACT', response.data.data)
            }
            return true;

        }).catch(err => {
            return false
        })
}


export const getContactById = ({
    commit
}, {
    id
}) => {
    get(`/api/v2/contact/detail?id=${id}`)
        .then(response => {
            commit('SET_CONTACT', response.data.data)
        })
}

export const createContact = ({
    commit
}, data) => {

    return post('api/v2/contact/create', {
        data
    })
        .then(response => {
            let res = response.data;

            if (res.success == false && res.error_code == 'VALIDATION_ERROR') {
                commit('SET_VALIDATION_ERROR', res)

                return false;
            }

            return true;
        })
        .catch(err => {
            return false;
        })
}

export const updateContact = ({
    commit
}, data) => {
    return post('api/v2/contact/update', {
        data
    })
        .then(response => {
            if (response.data.success == false) {
                commit('SET_VALIDATION_ERROR', response.data)
                return false;
            }
            return true;
        })
}
export const deleteContact = ({
    commit
}, data) => {
    post('/api/v2/contact/delete', {
        data
    })
        .then(response => {
            store.dispatch('getListContact')
        })
}

// module api worker

export const displayWorker = ({
    commit
}) => {
    get('/api/v2/worker/display')
}

export const getListWorker = ({
    commit
}, data) => {
    commit('SET_LIST_WORKER', data)
}


export const getOptionWorker = ({
    commit
}, data) => {
    return get('/api/v2/worker/')
        .then(response => {
            let res = response.data;
            if (res.success) {
                commit('SET_OPTION_WORKER', res.data)
            }
            return true;
        }).catch(err => {
            return false
        })
}


export const addWorker = ({
    commit
}, data) => {
    return post('/api/v2/worker/create', { data })
        .then(response => {
            let res = response.data;

            if (response.data.success == false) {
                commit('SET_VALIDATION_ERROR', res)

                return false;
            }

            return true;

        }).catch(err => {
            return false;
        })
}

export const deleteWorker = ({
    commit
}, data) => {
    return post('/api/v2/worker/delete', { data })
        .then(response => {
            let res = response.data;

            if (response.data.success == false) {
                commit('SET_VALIDATION_ERROR', res)

                return false;
            }

            return true;

        }).catch(err => {
            return false;
        })
}


export const displayQR = ({
    commit
}) => {
    get('/api/v2/worker/qrcode')
}

export const loadingQr = ({
    commit
}) => {
    commit('SET_LOADING', true)
}

export const closeQR = ({
    commit
}) => {
    get('/api/v2/worker/close')
}

export const resendMsg = ({
    commit
}, {
    id
}) => {
    get(`/api/v2/worker/resend?message_id=${id}`)
        .then(response => {
            store.dispatch('getListMessage')
        })
}